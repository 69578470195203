import { useAuthUser } from "@crema/utility/AppHooks";
import React from "react";
import { Redirect } from "react-router-dom";

const Welcome = () => {
  const profile = useAuthUser();
  return profile?.realRole?.mainPage ? (
    <Redirect to={`${profile.realRole.mainPage}`} />
  ) : null;
};

const WELCOME = {
  path: "/welcome",
  component: () => <Welcome />,
};

const STUDENT_PROGRESS = {
  path: "/student-progress/:id",
  component: React.lazy(() => import("./Pages/StudentProgress")),
};

const MONTHLY_HOURS = {
  path: "/monthly-hours",
  component: React.lazy(() => import("./Pages/MonthlyHours")),
};

const AVAILABILITY = {
  path: "/availability",
  component: React.lazy(() => import("./Pages/Availability")),
};

const BOOKINGS = {
  path: "/bookings",
  component: React.lazy(() => import("./Pages/Bookings")),
};

const CHECKIN = {
  path: "/checkin",
  component: React.lazy(() => import("./Pages/Checkin")),
};

const PILOTS = {
  path: "/pilots",
  component: React.lazy(() => import("./Pages/Pilots")),
};

const STATS = {
  path: "/stats",
  component: React.lazy(() => import("./Pages/Stats")),
};

const PILOT_DETAIL = {
  path: "/pilotDetail/:id",
  component: React.lazy(() => import("./Pages/PilotDetail")),
};

const PPA_DETAIL = {
  path: "/ppaDetail/:id",
  component: React.lazy(() => import("./Pages/PPADetail")),
};

const DASHBOARD = {
  path: "/dashboard",
  component: React.lazy(() => import("./Pages/Dashboard")),
};

const ACADEMIC_PROGRESS_CHECK = {
  path: "/academic-progress-check/:id",
  component: React.lazy(() => import("./Pages/AcademicProgressCheck")),
};

const ACADEMIC_PROGRESS = {
  path: "/academic-progress/:id",
  component: React.lazy(() => import("./Pages/AcademicProgress")),
};

const ADVANCE_COURSES = {
  path: "/advance-courses",
  exact: true,
  component: React.lazy(() => import("./Pages/AdvanceCourses")),
};

const COURSES = {
  path: "/courses",
  exact: true,
  component: React.lazy(() => import("./Pages/Courses")),
};
const COURSES_DETAIL = {
  path: "/courses/:courseId",
  exact: true,
  component: React.lazy(() => import("./Pages/CourseDetail")),
};

const COURSES_CREATE = {
  path: "/courses/create",
  exact: true,
  component: React.lazy(() => import("./Pages/CoursesCreate")),
};

const COURSES_EDIT = {
  path: "/courses/:courseId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/CourseEdit")),
};

const SHIFTS = {
  path: "/shifts",
  exact: true,
  component: React.lazy(() => import("./Pages/Shifts")),
};

const DASHBOARD_ADMINISTRATIVE = {
  path: "/dashboard-administrative",
  component: React.lazy(() => import("./Pages/DashboardAdministrative")),
};

const DASHBOARD_ACCOMMODATION = {
  path: "/accommodation-dashboard",
  component: React.lazy(() => import("./Pages/DashboardAccommodation")),
};

const LATE_ARRIVALS = {
  path: "/late-arrivals",
  component: React.lazy(() => import("./Pages/LateArrivals")),
};

const WEEKLY_REPORT = {
  path: "/weekly-report",
  component: React.lazy(() => import("./Pages/WeeklyReport")),
};

const DEALS = {
  path: "/deals/",
  exact: true,
  component: React.lazy(() => import("./Pages/Deals")),
};

const DEALS_CREATE = {
  path: "/deals/create/:studentId?",
  component: React.lazy(() => import("./Pages/DealsCreate")),
};

const DEALS_DETAIL = {
  path: "/deals/:dealId?",
  component: React.lazy(() => import("./Pages/DealDetail")),
};

const ALERTS = {
  path: "/alerts",
  exact: true,
  component: React.lazy(() => import("./Pages/Alerts")),
};

const ALERTS_CREATE = {
  path: "/alerts/create",
  exact: true,
  component: React.lazy(() => import("./Pages/AlertsCreate")),
};

const ALERTS_EDIT = {
  path: "/alerts/:alertId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/AlertsEdit")),
};

const PRICE_LIST = {
  path: "/price-list",
  exact: true,
  component: React.lazy(() => import("./Pages/PriceList")),
};

const PRICE_LIST_CREATE = {
  path: "/price-list/create",
  exact: true,
  component: React.lazy(() => import("./Pages/PriceListCreate")),
};

const ORGANIZATIONS = {
  path: "/organizations",
  exact: true,
  component: React.lazy(() => import("./Pages/Organizations")),
};

const ORGANIZATIONS_CREATE = {
  path: "/organizations/create",
  exact: true,
  component: React.lazy(() => import("./Pages/OrganizationsCreate")),
};

const ORGANIZATIONS_EDIT = {
  path: "/organizations/:organizationId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/OrganizationsEdit")),
};

const SUPPLIERS = {
  path: "/suppliers",
  exact: true,
  component: React.lazy(() => import("./Pages/Suppliers")),
};

const SUPPLIERS_CREATE = {
  path: "/suppliers/create",
  exact: true,
  component: React.lazy(() => import("./Pages/SuppliersCreate")),
};

const SUPPLIERS_EDIT = {
  path: "/suppliers/:supplierId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/SuppliersEdit")),
};

const PRODUCTS = {
  path: "/products",
  exact: true,
  component: React.lazy(() => import("./Pages/Products")),
};

const PRODUCTS_CREATE = {
  path: "/products/create",
  exact: true,
  component: React.lazy(() => import("./Pages/ProductsCreate")),
};

const PRODUCTS_EDIT = {
  path: "/products/:supplierId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/ProductsEdit")),
};

const WITHDRAWAL = {
  path: "/withdrawal",
  exact: true,
  component: React.lazy(() => import("./Pages/Withdrawal")),
};

const WITHDRAWAL_CREATE = {
  path: "/withdrawal/create",
  exact: true,
  component: React.lazy(() => import("./Pages/WithdrawalCreate")),
};

const WITHDRAWAL_EDIT = {
  path: "/withdrawal/:withdrawalId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/WithdrawalEdit")),
};

const FINANCIAL_ENTITIES = {
  path: "/financial-entities",
  exact: true,
  component: React.lazy(() => import("./Pages/FinancialEntities")),
};

const FINANCIAL_ENTITIES_CREATE = {
  path: "/financial-entities/create",
  exact: true,
  component: React.lazy(() => import("./Pages/FinancialEntitiesCreate")),
};

const FINANCIAL_ENTITIES_EDIT = {
  path: "/financial-entities/:financialEntityId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/FinancialEntitiesEdit")),
};

const FINANCIAL_ENTITY_BALANCE = {
  path: "/financial-entity-balance",
  exact: true,
  component: React.lazy(() => import("./Pages/FinancialEntityBalance")),
};

const TEMPORARY_FUND = {
  path: "/temporary-fund",
  exact: true,
  component: React.lazy(() => import("./Pages/TemporaryFund")),
};

const EXCHANGE_ENTITIES = {
  path: "/exchange-entities",
  exact: true,
  component: React.lazy(() => import("./Pages/ExchangeEntities")),
};

const EXCHANGE_ENTITIES_CREATE = {
  path: "/exchange-entities/create",
  exact: true,
  component: React.lazy(() => import("./Pages/ExchangeEntitiesCreate")),
};

const EXCHANGE_ENTITIES_EDIT = {
  path: "/exchange-entities/:exchangeEntityId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/ExchangeEntitiesEdit")),
};

const EXCHANGE_ENTITY_BALANCE = {
  path: "/exchange-entity-balance",
  exact: true,
  component: React.lazy(() => import("./Pages/ExchangeEntityBalance")),
};

const EXCHANGE_ENTITY_TRANSACTION_CREATE = {
  path: "/exchange-entity/transactions/create",
  exact: true,
  component: React.lazy(
    () => import("./Pages/ExchangeEntityTransactionCreate")
  ),
};

const EXCHANGE_ENTITY_TRANSACTION_EDIT = {
  path: "/exchange-entity/transactions/:id/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/ExchangeEntityTransactionEdit")),
};

const PRODUCT_CATEGORIES = {
  path: "/product-categories",
  exact: true,
  component: React.lazy(() => import("./Pages/ProductCategories")),
};

const PRODUCT_CATEGORIES_CREATE = {
  path: "/product-categories/create",
  exact: true,
  component: React.lazy(() => import("./Pages/ProductCategoriesCreate")),
};

const PRODUCT_CATEGORIES_EDIT = {
  path: "/product-categories/:productCategoryId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/ProductCategoriesEdit")),
};

const OUTFLOWS = {
  path: "/outflows",
  exact: true,
  component: React.lazy(() => import("./Pages/Outflows")),
};

const OUTFLOWS_ITEMS = {
  path: "/outflows-items",
  exact: true,
  component: React.lazy(() => import("./Pages/OutflowsItems")),
};

const OUTFLOWS_CREATE = {
  path: "/outflows/create",
  exact: true,
  component: React.lazy(() => import("./Pages/OutflowsCreate")),
};

const OUTFLOWS_DETAIL = {
  path: "/outflows/:outflowId",
  exact: true,
  component: React.lazy(() => import("./Pages/OutflowsView")),
};

const OUTFLOWS_EDIT = {
  path: "/outflows/:outflowId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/OutflowsEdit")),
};

const OUTFLOWS_TAXES = {
  path: "/outflows-taxes",
  exact: true,
  component: React.lazy(() => import("./Pages/OutflowsTaxes")),
};

const USER_ACCOUNT_BALANCE = {
  path: "/user-account-balance",
  expect: true,
  component: React.lazy(() => import("./Pages/UserAccountBalance")),
};
const USER_ACCOUNT_BALANCE_DETAIL = {
  path: "/user-account-balance-detail/:userId",
  expect: true,
  component: React.lazy(() => import("./Pages/UserAccountBalanceDetail")),
};

const MOVEMENTS = {
  path: "/movements",
  exact: true,
  component: React.lazy(() => import("./Pages/Movements")),
};

const INFLOWS = {
  path: "/inflows",
  exact: true,
  component: React.lazy(() => import("./Pages/Inflows")),
};

const INFLOWS_EXPIRATION = {
  path: "/inflows-expiration",
  exact: true,
  component: React.lazy(() => import("./Pages/InflowsItems")),
};

const OUTFLOWS_DASHBOARD = {
  path: "/outflow-dashboard",
  exact: true,
  component: React.lazy(() => import("./Pages/OutflowsDashboard")),
};

const INFLOWS_CREATE = {
  path: "/inflows/create",
  exact: true,
  component: React.lazy(() => import("./Pages/InflowsCreate")),
};

const INFLOWS_EDIT = {
  path: "/inflows/:inflowId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/InflowsEdit")),
};

const PAYMENTS = {
  path: "/payments",
  exact: true,
  component: React.lazy(() => import("./Pages/Payments")),
};
const PAYMENTS_CREATE = {
  path: "/payments/create/:outflowId?",
  exact: true,
  component: React.lazy(() => import("./Pages/PaymentsCreate")),
};
const PAYMENTS_DETAIL = {
  path: "/payments/:paymentId",
  exact: true,
  component: React.lazy(() => import("./Pages/PaymentsView")),
};
const PAYMENTS_EDIT = {
  path: "/payments/:paymentId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/PaymentsEdit")),
};

const TRANSFERS = {
  path: "/transfers",
  exact: true,
  component: React.lazy(() => import("./Pages/Transfers")),
};

const TRANSFERS_CREATE = {
  path: "/transfers/create/",
  exact: true,
  component: React.lazy(() => import("./Pages/TransferCreate")),
};

const TRANSFERS_EDIT = {
  path: "/transfers/:transferId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/TransferEdit")),
};

const RESOURCES = {
  path: "/resources",
  exact: true,
  component: React.lazy(() => import("./Pages/Resources")),
};

const RESOURCES_CREATE = {
  path: "/resources/create",
  exact: true,
  component: React.lazy(() => import("./Pages/ResourcesCreate")),
};

const RESOURCES_EDIT = {
  path: "/resources/:resourceId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/ResourcesEdit")),
};

const ACCOMMODATION_GROUPS = {
  path: "/accommodation-group",
  exact: true,
  component: React.lazy(() => import("./Pages/AccommodationGroups")),
};

const ACCOMMODATION_GROUPS_CREATE = {
  path: "/accommodation-group/create",
  exact: true,
  component: React.lazy(() => import("./Pages/AccommodationGroupsCreate")),
};

const ACCOMMODATION_GROUPS_EDIT = {
  path: "/accommodation-group/:accommodationGroupId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/AccommodationGroupsEdit")),
};

const ACCOMMODATION = {
  path: "/accommodation",
  exact: true,
  component: React.lazy(() => import("./Pages/Accommodations")),
};

const ACCOMMODATION_CREATE = {
  path: "/accommodation/create",
  exact: true,
  component: React.lazy(() => import("./Pages/AccommodationsCreate")),
};

const ACCOMMODATION_EDIT = {
  path: "/accommodation/:accommodationId/edit",
  exact: true,
  component: React.lazy(() => import("./Pages/AccommodationsEdit")),
};

const ACCOMMODATION_STAYS = {
  path: "/accommodation-stay",
  exact: true,
  component: React.lazy(() => import("./Pages/AccommodationStays")),
};

const ACCOMMODATION_STAY_CHECKIN_CREATE = {
  path: "/accommodation-stay/checkin-create",
  exact: true,
  component: React.lazy(() => import("./Pages/AccommodationStayCheckInCreate")),
};

const ACCOMMODATION_STAY_CHECKIN_EDIT = {
  path: "/accommodation-stay/:checkinId/checkin-edit",
  exact: true,
  component: React.lazy(() => import("./Pages/AccommodationStayCheckInEdit")),
};

const OTHERS_METEOROLOGY = {
  path: "/others/meteorology",
  exact: true,
  component: React.lazy(() => import("./Pages/OthersMeteorology")),
};
const OTHERS_LIBRARY = {
  path: "/others/library",
  exact: true,
  component: React.lazy(() => import("./Pages/OthersLibrary")),
};
const OTHERS_CONTACTS = {
  path: "/others/contacts",
  exact: true,
  component: React.lazy(() => import("./Pages/OthersContact")),
};

export const samplePagesConfig = [
  {
    routes: [WELCOME],
    auth: [
      "instructor-chief",
      "administrative",
      "administrative-plus",
      "accommodation-manager",
      "super-admin",
      "finance",
      "finance-assistant",
      "admin",
      "father",
      "tutor",
      "sales",
      "ppa",
      "appa",
      "desk-room",
    ],
  }, //ALL ROLES
  {
    routes: [STUDENT_PROGRESS],
    auth: ["super-admin", "father", "tutor", "admin", "appa", "ppa"],
  },
  {
    routes: [MONTHLY_HOURS],
    auth: [
      "super-admin",
      "tutor",
      "admin",
      "administrative",
      "administrative-plus",
      "instructor-chief",
    ],
  },
  {
    routes: [AVAILABILITY],
    auth: [
      "super-admin",
      "admin",
      "administrative",
      "administrative-plus",
      "desk-room",
    ],
  },
  {
    routes: [BOOKINGS],
    auth: ["super-admin", "admin", "administrative", "administrative-plus"],
  },
  {
    routes: [CHECKIN],
    auth: [
      "super-admin",
      "admin",
      "administrative",
      "administrative-plus",
      "desk-room",
    ],
  },
  {
    routes: [PILOTS],
    auth: [
      "super-admin",
      "tutor",
      "admin",
      "administrative",
      "administrative-plus",
      "instructor-chief",
      "sales",
      "academic-coordination",
    ],
  },
  {
    routes: [STATS],
    auth: [
      "super-admin",
      "admin",
      "administrative",
      "administrative-plus",
      "sales",
    ],
  },
  {
    routes: [PILOT_DETAIL],
    auth: [
      "super-admin",
      "father",
      "tutor",
      "admin",
      "instructor-chief",
      "sales",
      "appa",
      "ppa",
    ],
  },
  {
    routes: [PPA_DETAIL],
    auth: [
      "super-admin",
      "father",
      "tutor",
      "admin",
      "instructor-chief",
      "sales",
    ],
  },
  {
    routes: [DASHBOARD],
    auth: [
      "super-admin",
      "tutor",
      "admin",
      "instructor-chief",
      "sales",
      "desk-room",
      "academic-coordination",
    ],
  },
  {
    routes: [ACADEMIC_PROGRESS],
    auth: [
      "super-admin",
      "tutor",
      "admin",
      "instructor-chief",
      "sales",
      "appa",
      "ppa",
    ],
  },
  {
    routes: [ACADEMIC_PROGRESS_CHECK],
    auth: [
      "super-admin",
      "tutor",
      "admin",
      "instructor-chief",
      "sales",
      "appa",
      "ppa",
    ],
  },
  {
    routes: [ADVANCE_COURSES],
    auth: ["super-admin", "tutor", "admin", "sales", "academic-coordination"],
  },
  {
    routes: [COURSES],
    auth: ["super-admin", "tutor", "admin", "sales", "academic-coordination"],
  },
  {
    routes: [DASHBOARD_ADMINISTRATIVE],
    auth: ["super-admin", "admin", "administrative", "administrative-plus"],
  },
  { routes: [COURSES], auth: ["super-admin", "admin"] },
  { routes: [COURSES_CREATE], auth: ["super-admin", "admin"] },
  { routes: [COURSES_EDIT], auth: ["super-admin", "admin"] },
  { routes: [COURSES_DETAIL], auth: ["super-admin", "admin"] },
  { routes: [SHIFTS], auth: ["super-admin", "admin"] },
  {
    routes: [LATE_ARRIVALS],
    auth: [
      "super-admin",
      "tutor",
      "admin",
      "administrative",
      "administrative-plus",
      "instructor-chief",
      "sales",
      "desk-room",
      "academic-coordination",
    ],
  },
  {
    routes: [WEEKLY_REPORT],
    auth: [
      "super-admin",
      "tutor",
      "admin",
      "administrative",
      "administrative-plus",
      "instructor-chief",
      "sales",
      "academic-coordination",
    ],
  },
  {
    routes: [DEALS],
    auth: [
      "super-admin",
      "tutor",
      "admin",
      "sales",
      "desk-room",
      "academic-coordination",
    ],
  },
  {
    routes: [DEALS_CREATE],
    auth: ["super-admin", "tutor", "admin", "sales", "desk-room"],
  },
  {
    routes: [DEALS_DETAIL],
    auth: ["super-admin", "tutor", "admin", "sales", "desk-room"],
  },
  {
    routes: [ALERTS],
    auth: ["super-admin", "admin", "desk-room", "academic-coordination"],
  },
  { routes: [ALERTS_CREATE], auth: ["super-admin", "admin", "desk-room"] },
  { routes: [ALERTS_EDIT], auth: ["super-admin", "admin", "desk-room"] },
  { routes: [PRICE_LIST], auth: ["super-admin", "finance"] },
  { routes: [PRICE_LIST_CREATE], auth: ["super-admin", "finance"] },
  {
    routes: [ORGANIZATIONS],
    auth: ["super-admin", "finance", "finance-assistant"],
  },
  { routes: [ORGANIZATIONS_CREATE], auth: ["super-admin", "finance"] },
  { routes: [ORGANIZATIONS_EDIT], auth: ["super-admin", "finance"] },
  {
    routes: [SUPPLIERS],
    auth: ["super-admin", "finance", "finance-assistant"],
  },
  {
    routes: [SUPPLIERS_CREATE],
    auth: ["super-admin", "finance", "finance-assistant"],
  },
  {
    routes: [SUPPLIERS_EDIT],
    auth: ["super-admin", "finance", "finance-assistant"],
  },
  {
    routes: [PRODUCTS],
    auth: ["super-admin", "finance", "finance-assistant", "sales"],
  },
  {
    routes: [PRODUCTS_CREATE],
    auth: ["super-admin", "finance", "finance-assistant", "sales"],
  },
  {
    routes: [PRODUCTS_EDIT],
    auth: ["super-admin", "finance", "finance-assistant", "sales"],
  },
  {
    routes: [WITHDRAWAL],
    auth: [
      "super-admin",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [WITHDRAWAL_CREATE],
    auth: [
      "super-admin",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [WITHDRAWAL_EDIT],
    auth: [
      "super-admin",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [FINANCIAL_ENTITIES],
    auth: ["super-admin", "finance", "finance-assistant"],
  },
  {
    routes: [FINANCIAL_ENTITIES_CREATE],
    auth: ["super-admin", "finance", "finance-assistant"],
  },
  {
    routes: [FINANCIAL_ENTITIES_EDIT],
    auth: ["super-admin", "finance", "finance-assistant"],
  },

  {
    routes: [FINANCIAL_ENTITY_BALANCE],
    auth: [
      "super-admin",
      "administrative",
      "administrative-plus",
      "finance",
      "desk-room",
    ],
  },
  { routes: [EXCHANGE_ENTITIES], auth: ["super-admin", "finance"] },
  { routes: [EXCHANGE_ENTITIES_CREATE], auth: ["super-admin", "finance"] },
  { routes: [EXCHANGE_ENTITIES_EDIT], auth: ["super-admin", "finance"] },
  { routes: [EXCHANGE_ENTITY_BALANCE], auth: ["super-admin", "finance"] },
  {
    routes: [EXCHANGE_ENTITY_TRANSACTION_CREATE],
    auth: ["super-admin", "finance"],
  },
  {
    routes: [EXCHANGE_ENTITY_TRANSACTION_EDIT],
    auth: ["super-admin", "finance"],
  },
  {
    routes: [TEMPORARY_FUND],
    auth: [
      "super-admin",
      "finance",
      "administrative",
      "administrative-plus",
      "desk-room",
    ],
  },
  {
    routes: [PRODUCT_CATEGORIES],
    auth: ["super-admin", "finance", "finance-assistant"],
  },
  { routes: [PRODUCT_CATEGORIES_CREATE], auth: ["super-admin", "finance"] },
  { routes: [PRODUCT_CATEGORIES_EDIT], auth: ["super-admin", "finance"] },
  {
    routes: [OUTFLOWS],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [OUTFLOWS_ITEMS],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [OUTFLOWS_DASHBOARD],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [OUTFLOWS_CREATE],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [OUTFLOWS_DETAIL],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [OUTFLOWS_EDIT],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [OUTFLOWS_TAXES],
    auth: [
      "super-admin",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [USER_ACCOUNT_BALANCE],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "father",
      "sales",
      "desk-room",
    ],
  },
  {
    routes: [USER_ACCOUNT_BALANCE_DETAIL],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "father",
      "sales",
      "desk-room",
      "appa",
      "ppa",
    ],
  },
  {
    routes: [MOVEMENTS],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "appa",
      "ppa",
    ],
  },
  {
    routes: [PAYMENTS],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [PAYMENTS_CREATE],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [PAYMENTS_DETAIL],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [PAYMENTS_EDIT],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative",
      "administrative-plus",
      "finance",
      "finance-assistant",
    ],
  },
  {
    routes: [TRANSFERS],
    auth: ["super-admin", "administrative", "administrative-plus", "finance"],
  },
  {
    routes: [TRANSFERS_CREATE],
    auth: ["super-admin", "administrative", "administrative-plus", "finance"],
  },
  {
    routes: [TRANSFERS_EDIT],
    auth: ["super-admin", "administrative", "administrative-plus", "finance"],
  },
  {
    routes: [INFLOWS],
    auth: [
      "super-admin",
      "finance",
      "sales",
      "administrative",
      "administrative-plus",
      "desk-room",
    ],
  },
  {
    routes: [INFLOWS_EXPIRATION],
    auth: [
      "super-admin",
      "finance",
      "sales",
      "administrative",
      "administrative-plus",
      "desk-room",
    ],
  },
  {
    routes: [INFLOWS_CREATE],
    auth: [
      "super-admin",
      "finance",
      "sales",
      "administrative",
      "administrative-plus",
      "desk-room",
    ],
  },
  {
    routes: [INFLOWS_EDIT],
    auth: [
      "super-admin",
      "finance",
      "sales",
      "administrative",
      "administrative-plus",
    ],
  },
  { routes: [RESOURCES], auth: ["super-admin", "finance"] },
  { routes: [RESOURCES_CREATE], auth: ["super-admin", "finance"] },
  { routes: [RESOURCES_EDIT], auth: ["super-admin", "finance"] },
  {
    routes: [DASHBOARD_ACCOMMODATION],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative-plus",
      "sales",
    ],
  },
  {
    routes: [ACCOMMODATION_GROUPS],
    auth: ["super-admin", "accommodation-manager", "administrative-plus"],
  },
  {
    routes: [ACCOMMODATION_GROUPS_CREATE],
    auth: ["super-admin", "accommodation-manager", "administrative-plus"],
  },
  {
    routes: [ACCOMMODATION_GROUPS_EDIT],
    auth: ["super-admin", "accommodation-manager", "administrative-plus"],
  },
  {
    routes: [ACCOMMODATION],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative-plus",
      "sales",
    ],
  },
  {
    routes: [ACCOMMODATION_CREATE],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative-plus",
      "sales",
    ],
  },
  {
    routes: [ACCOMMODATION_EDIT],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative-plus",
      "sales",
    ],
  },
  {
    routes: [ACCOMMODATION_STAYS],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative-plus",
      "sales",
    ],
  },
  {
    routes: [ACCOMMODATION_STAY_CHECKIN_CREATE],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative-plus",
      "sales",
    ],
  },
  {
    routes: [ACCOMMODATION_STAY_CHECKIN_EDIT],
    auth: [
      "super-admin",
      "accommodation-manager",
      "administrative-plus",
      "sales",
    ],
  },
  {
    routes: [OTHERS_METEOROLOGY],
    auth: ["super-admin", "ppa", "appa"],
  },
  {
    routes: [OTHERS_LIBRARY],
    auth: ["super-admin", "ppa", "appa"],
  },
  {
    routes: [OTHERS_CONTACTS],
    auth: ["super-admin", "ppa", "appa"],
  },
];
